import React from 'react';
import { FormattedMessage } from "react-intl";

const Card = ({lab, labtext}) => {
  return (
    <div className="section3-card">
      <img src={lab} alt="" />
      <p className="text"><FormattedMessage id={labtext} defaultMessage="" description="" /></p>
    </div>
  )
}

export default Card;