import React, { useState, useContext, useEffect, useRef } from "react";
import { RiMenu3Line, RiCloseLine, RiGlobalLine } from "react-icons/ri";
import logo from "../../logo.png";
import "./navbar.css";
import { FormattedMessage, useIntl } from "react-intl";
import { Context } from "../Wrapper/Wrapper";
import { cat, esp } from './imports';


const Menu = () => {
  const context = useContext(Context);
  const [toggleLangMenu, setToggleLangMenu] = useState(false);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const intl = useIntl();

  return (
    <nav>
      <ul>
        <div className="navitem">
          <li>
            <a href="#servicios">
              <FormattedMessage id="nav.section1" defaultMessage="Servicios" />
            </a>
          </li>
        </div>
        <div className="navitem">
          <li>
            <a href="#equipo">
              <FormattedMessage id="nav.section2" defaultMessage="Equipo" />
            </a>
          </li>
        </div>
        <div className="navitem">
          <li>
            <a href="#laboratorio">
              <FormattedMessage
                id="nav.section3" defaultMessage="Laboratorio" />
            </a>
          </li>
        </div>
        <div className="navitem">
          <li>
            <a href="#contacto">
              <FormattedMessage id="nav.contacto" defaultMessage="Contacto" />
            </a>
          </li>
        </div>
        <div className="navitem">
          <li>
            {toggleLangMenu ? (
              <button type="button" role="listbox" onClick={() => { setToggleLangMenu(false); toggleOptions(); }} aria-label={intl.formatMessage({ id: "change.language"})} aria-expanded={isOptionsOpen} aria-controls="myDropdown" className="dropbtn-main">
                <RiGlobalLine
                  color="#fff"
                  size={25}
                />
              </button>
            ) : (
              <button type="button" role="listbox" onClick={() => { setToggleLangMenu(true); toggleOptions(); }} aria-label={intl.formatMessage({ id: "change.language"})} aria-expanded={isOptionsOpen} aria-controls="myDropdown" className="dropbtn-main">
                <RiGlobalLine
                  color="#fff"
                  size={25}
                />
              </button>
            )}
            {toggleLangMenu && (
              <div className="dropdown scale-up-top">
                <div className="navbar-menu_container-links">
                <div id="myDropdown" role="menubar" className="dropdown scale-up-top">
                  <ul className="lang-list">
                    <li>
                      <input type="image" role="menuitem" id="cat" aria-label="català" className="dropbtn" onClick={context.selectLang} value="ca-ES" src={cat} />
                    </li>
                {/* <li><input type="image" role="menuitem" aria-label="français" className="dropbtn" onClick={context.selectLang} value="fr-FR" src={fra} />
                </li>*/}
                    <li>
                      <input type="image" role="menuitem" id="esp" aria-label="español" className="dropbtn" onClick={context.selectLang} value="es-ES" src={esp} />
                    </li>
                  </ul>
                </div>
                </div>
              </div>
            )}
          </li>
        </div>
      </ul>
    </nav>
  );
};

const Navbar = () => {
  const ref = useRef();
  const [toggleMenu, setToggleMenu] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (toggleMenu && ref.current && !ref.current.contains(e.target)) {
        setToggleMenu(false);
      }
    };
    
    document.addEventListener('click', checkIfClickedOutside);

    return () =>  { 
      document.removeEventListener('click', checkIfClickedOutside);
    };
  }, [toggleMenu]);

  return (
    <div className="navbar">
      <div className="navbar-links">
        <div className="navbar-links_logo" role="banner">
          <div className="navbar-img-logo">
            <a className="logolink" href="#home">
              <img src={logo} alt="logo" />
            </a>
          </div>
          <div className="navbar-text-logo">
            <h2>BASSETS 3D LAB</h2>
          </div>
        </div>
        <div className="navbar-links_container">
          <Menu />
        </div>
      </div>

      <div className="navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="navbar-menu_container slide-in-right">
            <div className="navbar-menu_container-links" ref={ref}>
              <Menu />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;