import React, { useState, useEffect, useRef } from 'react';
import logo from '../../logo.png';
import { StyledFooter } from "./Footer.styled";
import ModalManager from "../../components/Modal/ModalManager";
import Modalbox from "../../components/Modal/Modalbox";

const Footer = () => {
  const ref = useRef();  
  const [modalOpen, setModal] = useState(false);

  const openModal = event => {
    event.preventDefault();
    const {
      target: {
        dataset: { modal }
      }
    } = event;
    if (modal) setModal(modal);
  };

  const closeModal = () => {
    setModal('');
  };

  useEffect(() => {
    const checkIfModalOpen = (e) => {
      if (modalOpen && ref.current && !ref.current.contains(e.target)) {
        closeModal();
      }
    };
    
    document.addEventListener('click', checkIfModalOpen);

    return () =>  { 
      document.removeEventListener('click', checkIfModalOpen);
    };
  }, [modalOpen]);
  

  return (
    <StyledFooter id="footer">
      <div className="footer-logo">
        <img src={logo} alt="logo" />
      </div>
      <div className="footer-column">
        <div id="modal-root" />
        <div className="modal-shell" onClick={openModal} ref={ref}>
          <Modalbox />
          <ModalManager closeFn={closeModal} modal={modalOpen} />
        </div>
        <div className="copyright">&#169; Design by&nbsp;<a href="https://nyxy-portfolio.netlify.app/">Nyxy Web</a></div>
      </div>
    </StyledFooter>
  )
};

export default Footer;