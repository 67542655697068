import React from 'react';
import './section1.css';
import { icon1, icon2, icon3 } from './imports';
import Carousel from 'react-multi-carousel';
import { FormattedMessage } from "react-intl";
import 'react-multi-carousel/lib/styles.css';
import { ParallaxBanner } from "react-scroll-parallax";

const richText = {
  li: chunks => <li>{chunks}</li>,
}

const Section1 = () => {

  return (
      <div className="section1" id="servicios">
        <div className="title"><h1><FormattedMessage id="nav.section1" defaultMessage="Servicios" /></h1>
        </div>
          <div className="section1-text">
            <p><FormattedMessage id="section1.intro" defaultMessage="" description="Estámos especializados análogica y digitalmente en" /></p>
          </div>
      <ParallaxBanner
        layers={[{ image: 'ban3.jpg', speed: -30 }]}
        className="parallaxban1"
        >
      <div className="section1-content">
          <div className="section1-cardtop">
            <div className="icondiv"><img src={icon3} alt="icono" /></div>
            <ul className="cutoff-text"><FormattedMessage id="section1.servicio1" defaultMessage="" description="Lista servicios de prótesis" values={{ ...richText }} /></ul>
            <input className="expand-btn" type="checkbox" />
          </div>
          <div className="section1-cardtop">
            <div className="icondiv"><img src={icon1} alt="icono" /></div>
            <ul className="cutoff-text"><FormattedMessage id="section1.servicio2" defaultMessage="" description="Lista servicios digitales" values={{ ...richText }} /></ul>
          </div>
          <div className="section1-cardtop">
            <div className="icondiv"><img src={icon2} alt="icono" /></div>
            <ul className="cutoff-text"><FormattedMessage id="section1.servicio3" defaultMessage="" description="Lista materiales utilizados" values={{ ...richText }} /></ul>
            <input className="expand-btn" type="checkbox" />
          </div>
      </div>
      </ParallaxBanner>
        
<Carousel
  additionalTransfrom={0}
  autoPlay={false}
  shouldResetAutoplay={false}
  swipeable
  draggable
  // showDots={true}
  responsive={{
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1050
      },
      items: 3,
      partialVisibilityGutter: 40
    },
    mobile: {
      breakpoint: {
        max: 550,
        min: 0
      },
      items: 1,
      partialVisibilityGutter: 30
    },
    tablet: {
      breakpoint: {
        max: 1050,
        min: 550
      },
      items: 2,
      partialVisibilityGutter: 30
    }
  }}
  infinite={true}
  keyBoardControl={true}
  slidesToSlide={1}
  containerClass="carousel-container"
  // removeArrowOnDeviceType={["tablet", "mobile"]}
  itemClass="carousel-item">
      <div><img src="./galeria/IMG_1.jpg" className="carousel-img" alt="" /></div>
      <div><img src="./galeria/IMG_2.jpg" className="carousel-img" alt="" /></div>
      <div><img src="./galeria/IMG_3.jpg" className="carousel-img" alt="" /></div>
      <div><img src="./galeria/IMG_4.jpg" className="carousel-img" alt="" /></div>
      <div><img src="./galeria/IMG_5.jpg" className="carousel-img" alt="" /></div>
      <div><img src="./galeria/IMG_6.jpg" className="carousel-img" alt="" /></div>
      <div><img src="./galeria/IMG_7.jpg" className="carousel-img" alt="" /></div>
      <div><img src="./galeria/IMG_8.jpg" className="carousel-img" alt="" /></div>
      <div><img src="./galeria/IMG_9.jpg" className="carousel-img" alt="" /></div>
      <div><img src="./galeria/IMG_10.jpg" className="carousel-img" alt="" /></div>
      <div><img src="./galeria/IMG_11.jpg" className="carousel-img" alt="" /></div>
      <div><img src="./galeria/IMG_12.jpg" className="carousel-img" alt="" /></div>
      <div><img src="./galeria/IMG_13.jpg" className="carousel-img" alt="" /></div>
</Carousel>
      </div>
  )
}

export default Section1;