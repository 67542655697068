import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
* {
  scroll-behavior: smooth;
  margin: 0px;
}

html, body {
  margin: 0px;
  padding: 0px;
  background: var(--color-bg);
  font-family: var(--font-family);
  text-rendering: optimizeLegibility;
}


*, *::after, *::before {
  box-sizing: border-box;
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: #eee;
}

h1 {
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  padding: 0.8rem 1rem;

  @media screen and (max-width: 650px) {
    font-size: 2rem;
  }
}

h2 {
font-weight: 400;
font-size: 1.88em;
line-height: 2rem;

@media screen and (max-width: 1050px) {
  font-size: 1.5rem;
  padding: 0;
  }
}

p {
  line-height: 2.25rem;
  font-size: 1.125rem;
  font-weight: 500;
  font-family: var(--font-menu);

  @media screen and (max-width:750px) {
    font-size: 1rem;
  }
}

.title {
  padding: 1rem 0;
  margin: 3rem 0;
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
  text-decoration-color: var(--color-dark);
  text-underline-offset: 1.125rem;
  color: var(--color-dark);
  font-family: var(--font-menu);
}

ul {
  list-style-type: none;
  padding: 0;
}


.App {
  display: flex;
  flex-direction: column;
}

.carousel-container {
  margin: 3rem 0;
  display: flex;
  text-align: center;
  width: 100%;
  height: 300px;
  max-width: 1200px;
  
}

.carousel-img {
  object-fit: cover;
  width: 100%;
  height: 300px;
  border: 4px solid transparent;
  border-radius: 20px;

  @media screen and (max-width: 1050px) {
    margin-left: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
  }
}
`