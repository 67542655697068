import React from "react";
import { FormattedMessage } from "react-intl";

const Modalbox = () => {
  return (
    <main>
      <div className="modal-div">
        <button type="button" data-modal="modal-one">
        <FormattedMessage id="footer.privacidad" defaultMessage="" description="Política de privacidad" />
        </button>
        <button type="button" data-modal="modal-two">
        <FormattedMessage id="footer.avisolegal" defaultMessage="" description="Aviso legal" />
        </button>
        <button type="button" data-modal="modal-three">
        <FormattedMessage id="footer.cookies" defaultMessage="" description="Cookies" />
        </button>
      </div>
    </main>
  );
};

export default Modalbox;