import React from 'react';
import Navbar from './components/Navbar/Navbar';
import { Header, Inicio, Section1, Section2, Section3, Contacto, Footer } from './containers';
import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from './global';
import { theme } from './theme';
import { useEffect } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import CookieConsent from "react-cookie-consent";
import Pdf from "./politicacookies.pdf"


const useScript = url => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = url;
    script.async = false;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
};


const App = () => {
  useScript("https://cdnjs.cloudflare.com/ajax/libs/gsap/2.1.3/easing/EasePack.min.js");
  useScript("./rAF.js");
  useScript("https://cdnjs.cloudflare.com/ajax/libs/gsap/2.1.3/TweenLite.min.js");
  useScript("./Headeranim.js");
  
  return (
    <ThemeProvider theme={theme}>
      <ParallaxProvider>
      <GlobalStyles />
      <Navbar />
      <Header />
      <Inicio />
      <Section1 />
      <Section2 />
      <Section3 />
      <Contacto />
      <Footer />
      </ParallaxProvider>
      <CookieConsent
      enableDeclineButton 
      setDeclineCookie
      location="bottom"
      buttonText="Acepto"
      declineButtonText="Declino"
      style={{ background: "#4E86E3", width: "" }}
      buttonStyle={{ color: "#fff", background: "#2E60BC", border: "solid 2px", borderRadius: "10px", paddingInline:"18px" }}
      declineButtonStyle={{ background: "#4E86E3", border: "solid 2px #ccc", borderRadius: "10px" }}
      expires={150}
      sameSite="none"
      cookieSecurity="true"
      onAccept={() => window.dataLayer.push({
        'event': 'new_consent_update'
      })}
    >
      {"Esta página web utiliza cookies para mejorar su experiencia"}<br/><a href={Pdf} target="_blank" rel="noreferrer">Leer Política de cookies</a>
    </CookieConsent>
    </ThemeProvider>
  );
}

export default App;
