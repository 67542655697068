import React from 'react';
import './inicio.css';
import { image01 } from './imports';
import { FormattedMessage } from "react-intl";

const Inicio = () => {
  return (
  <>
    <div className="section_inicio">
      <div className="title">
      {/*<h1><FormattedMessage id="inicio.title" defaultMessage="BASSETS 3D Lab" /></h1>*/}
      </div>
      <div className="inicio-content">
      <div className="inicio-text">
          <h1 className="inicio-titulo">Bassets 3D Lab</h1>
          <p><FormattedMessage id="inicio.text" defaultMessage="Somos un laboratorio de prótesis dental, cuyo objetivo principal es ser líderes en la prótesis digital realizando prótesis de máxima calidad mediante las facilidades del CAD/CAM." />
          </p>  
      </div>
          <img src={image01} alt="" />
      </div>
    </div>
  </>
  )
}

export default Inicio;