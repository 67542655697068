import React from "react";
import { FormattedMessage } from "react-intl";
import { StyledHeader } from "./Header.styled";

const Header = () => {

    return (
    <StyledHeader id="home">
      <div className="header-content ">
              <div id="large-header" className="large-header">
              <canvas id="demo-canvas"></canvas>
              </div>
        <div className="header-text">
      <h1 className="tracking-in-expand-fwd-top"><FormattedMessage id="app.header" defaultMessage="Laboratorio Dental Digital" /></h1>
      <h2 className="subtitle"><FormattedMessage id="app.header-sub" defaultMessage="Amamos lo que hacemos" /></h2>
        </div>
      </div>
    </StyledHeader>

  )
}

export default Header;