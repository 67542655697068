import React, { useState } from 'react';
import { Document, Page } from "react-pdf";
import cookies from "../../politicacookies.pdf";
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Modal from './Modal';

const ModalThree = ({ closeFn = () => null, open = false }) => {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  
  return (
    <Modal open={open}>
      <div className="modal--mask">
        <div className="modal-window">
          <header className="modal--header">
            <button className="closemodal" type="button" onClick={closeFn}>
              X Cerrar
            </button>            
          </header>
          <div className="modal--body">
          <Document className="modal" file={cookies} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (_el, index) => (
            <Page scale={1.2} key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
            </Document>
          </div>
          <footer className="modal--footer">
          </footer>
        </div>
      </div>
    </Modal>
  );
};

export default ModalThree;