import styled from 'styled-components';

export const StyledFooter = styled.footer`
  display: flex;
  padding: 2rem;

  p {
  padding: .5em;
  text-align: center;
  }

  img {
    padding: 2rem;
    height: 200px;
    
  @media screen and (max-width: 500px) {
      height: 120px;
    }
  }

  div {
    display: flex;

    &.footer-logo {
    flex-grow: 0;
    order: 1;
    }

    &.footer-column {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    order: 0;
    }
    
    &.copyright {
      margin-top: 3rem;
      a {
        color: var(--color-menu);
      }
    }
  }
`;