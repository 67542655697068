import React, { useState } from "react";
import { IntlProvider } from 'react-intl';
import Spanish from '../../languages/es-ES.json';
import French from '../../languages/fr-FR.json';
import Catalan from '../../languages/ca-ES.json';

export const Context = React.createContext();

const local = navigator.language;

let lang;
if (local === "fr-FR") {
  lang = French;
}
else if (local === "ca-ES") {
  lang = Catalan;
}
else {
  lang = Spanish;
}


const Wrapper = (props) => {
  const [locale, setLocale] = useState(local);
  const [messages, setMessages] = useState(lang);

  function selectLang(e) {
    const newLocale = e.target.value;
    setLocale(newLocale);
    if (newLocale === "es-ES") {
      setMessages(Spanish);
      document.documentElement.lang = "es";
    } 
    else if (newLocale === "ca-ES") {
      setMessages(Catalan);
      document.documentElement.lang = "ca-ES";
    } else {
      setMessages(French);
      document.documentElement.lang = "fr";
    }
  }


  return (
    <Context.Provider value={{ locale, selectLang }}>
      <IntlProvider messages={messages} locale={locale}>
      {props.children}
      </IntlProvider>
  </Context.Provider>
  )
}

export default Wrapper;