import React from 'react';
import './section3.css';
import { lab1, lab2, lab3, lab4 } from '../../components/Card/imports';
import Card from '../../components/Card/Card';
import { FormattedMessage } from "react-intl";

const Section3 = () => {
  return (
    <>
      <div className="section3" id="laboratorio">
        <div className="title"><h1><FormattedMessage id="nav.section3" defaultMessage="Laboratorio" /></h1></div>
        <div className="section3-content">
            <Card lab={lab1} labtext="section3.card1" />
            <Card lab={lab4} labtext="section3.card2" />
            <Card lab={lab3} labtext="section3.card3" />
            <Card lab={lab2} labtext="section3.card4" />
        </div>
      </div>
      <div className="ban3"></div>
    </>
  )
}

export default Section3;