import React from 'react';
import './contacto.css';
import { RiMailFill, RiPhoneFill, RiMapPin2Fill, RiTimeFill } from 'react-icons/ri';
import { FormattedMessage } from "react-intl";

const Contacto = () => {
  return (
    <section className="contacto" id="contacto">
      <div className="title">
        <h1 className="contacto-title"><FormattedMessage id="nav.contacto" defaultMessage="Contacto" /></h1>
      </div>
      <div className="contacto-content">
        <div className="contacto-column">
          <div className="contacto-icon">
            <div><RiMailFill size={35} color={"#1E3676"} /></div>
            <div><h2><FormattedMessage id="contacto.email" defaultMessage="E-mail" /></h2>
            <p><a href="mailto: bassets3dlab@gmail.com">bassets3dlab@gmail.com</a></p></div>
          </div>
          <div className="contacto-icon">
            <div><RiPhoneFill size={35} color={"#1E3676"} /></div>
            <div><h2><FormattedMessage id="contacto.phone" defaultMessage="Teléfono" /></h2>
            <p><a href="tel:+34935393165"> +34 935 39 31 65</a></p><p><a href="tel:+34644165464">+34 644 16 54 64</a></p></div>
          </div>
          <div className="contacto-icon">
            <div><RiMapPin2Fill size={35} color={"#1E3676"} /></div>
            <div><h2><FormattedMessage id="contacto.address" defaultMessage="Dirección" /></h2>
            <p>C/ Saragossa, 95-97</p>
            <p>08006 Barcelona</p></div>
          </div>
          <div className="contacto-icon">
            <div><RiTimeFill size={35} color={"#1E3676"} /></div>
            <div><h2><FormattedMessage id="contacto.time" defaultMessage="Horario" /></h2>
            <p><FormattedMessage id="contacto.time.text" defaultMessage="Lunes a Viernes 8h - 19h" /></p></div>
          </div>
        </div>        
        <div className="contacto-map">
        <iframe className="mapframe" title="mapa" frameBorder="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Carrer%20Saragossa%2095,%20Barcelona+(Bassets%203D%20Lab)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
        </div>
      </div>
    </section>
  )
}

export default Contacto;