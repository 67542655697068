import styled from 'styled-components';

export const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  z-index: 0;


  
  h1 {
    &.tracking-in-expand-fwd-top {
    letter-spacing: 2px;
    
    @media screen and (max-width: 1050px) {
      font-size: 2.4rem;

    }

    @media screen and (min-width: 500px) {
    
    -webkit-animation: tracking-in-expand-fwd-top 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
            animation: tracking-in-expand-fwd-top 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;

  @-webkit-keyframes tracking-in-expand-fwd-top {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(-500px);
            transform: translateZ(-700px) translateY(-500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes tracking-in-expand-fwd-top {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(-500px);
            transform: translateZ(-700px) translateY(-500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
    }
  }
}


  h2 {
  &.subtitle {
  font-family: var(--font-menu);
  font-weight: 400;
  font-size: 1.4em;
  line-height: 4rem;
  letter-spacing: 2px;
  text-transform: uppercase;

@media screen and (max-width: 550px) {
  font-size: 1.115rem;
  line-height: 2rem;
  }
  }
  }

  canvas {
    display: block;

  }

  div {
  &.header-content {
  height: 100vh;
  margin: 0px 0px;
  background-image: url("./divider.png"), url("./hero.png");
  background-position: bottom, center;
  background-repeat: no-repeat;
  background-size: contain, cover;

  @media screen and (max-width: 1000px) {

    background-size: contain, auto 100%;
  }
  }

  &.header-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--color-text-light);
  font-family: var(--font-family);
  }

  /* Particles Header */
  &.large-header {
	position: relative;
	z-index: 1;


  }
  }
`;