import React from 'react';
import './section2.css';
import { FormattedMessage } from "react-intl";
import { ParallaxBanner } from "react-scroll-parallax";

const Section2 = () => {

  return (
  
      <div className="section2" id="equipo">
        <div className="title"><h1><FormattedMessage id="nav.section2" defaultMessage="Equipo" /></h1></div>
      
      <ParallaxBanner
        layers={[{ image: 'ban2.jpg', speed: -30 }]}
        className="parallaxban2"
        >
      <div className="ban2">
        <div className="section2-content">
          <div className="section2-card">
            <p><FormattedMessage id="section2.intro" defaultMessage="" description="Bassets 3D Lab está coordinado por Albert Garcia Bassets, protésico dental con más de 30 años de experiencia." /></p>
          </div>
        </div>
      </div>
      </ParallaxBanner></div>
      
  )
}

export default Section2;